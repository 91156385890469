<script>
import {
  required,
  // email,
  minLength,
  // sameAs,
  maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
//import NumberInputSpinner from "vue-number-input-spinner";
/*import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "สร้างสาขาย่อย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
    //
  },

  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },

  created() {
    this.getDataDealer();
    this.getDataBrands();
    this.getProvinces();
  },

  data() {
    return {
      submitformDealer: false,

      title: "สร้างสาขาย่อย",
      items: [
        {
          text: "Dealer",
          href: "/",
        },
        {
          text: "formDealer Brach",
          active: true,
        },
      ],

      loading: undefined,
      optionsBrands:[],
      optionsDealer: [],
      dataTest: [],
      optionsZone:[
        {
          zoneId : "N",
          zoneName: "เหนือ"
        },
        {
          zoneId : "NE",
          zoneName: "ตะวันออกเฉียงเหนือ"
        },
        {
          zoneId : "E",
          zoneName: "ตะวันออก"
        },
        {
          zoneId : "C",
          zoneName: "กลาง"
        },
        {
          zoneId : "W",
          zoneName: "ตะวันตก"
        },
        {
          zoneId : "S",
          zoneName: "ใต้"
        },
        
      ],
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formdealer: {

        provinces:[],
        amphures:[],
        districts:[],

        dealerId: "", //รหัสดีลเลอร์ (สาขาย่อย)
        brandId:"",
        dealerBranchId: "",
        createdBy: "", //คนเพิ่มสาขา
        createdAt: "", //สร้างโดย
        updatedBy: "",
        updatedAt: "",
        deletedBy: "",
        deletedAt: "",
        isActive: "", // สถานะ
        branchNameTH: "", //สาขาต้นสังกัด ไทย  เต็ม
        branchShortNameTH: "", // สาขาต้นสังกัด ไทย ย่อ
        branchNameEN: "", //สาขาต้นสังกัด อังกฤษ  เต็ม
        branchShortNameEN: "", // สาขาต้นสังกัด อังกฤษ ย่อ
        vatId: "", //รหัสผู้เสียภาษี
        phoneNum: "", //โทรศัพท์

        isGS: "",
        isBP: "",
        addressTH: "", //ที่อยู่ ไทย
        addressEN: "", // ที่อยู่ อังกฤษ
        districtId: "", //ตำบล
        amphureId: "", //อำเภอ
        provinceId: "", //จังหวัด
        zoneId: "", //ภาค
        fullAddressTH: "", //รวมที่อยู่ ไทย
        fullAddressEN: "", // รวมที่อยู่ อังกฤษ */
        wagePrice: "",
        vatPer:"",
      },
    };
  },
  validations: {
    formdealer: {
      brandId:{
        required
      },  
      dealerId: {
        required
      },
      dealerBranchId: {
        required
      },
      branchNameTH: {
       required
      },
      branchShortNameTH: {
        
      },
      branchNameEN: {
        
      },
      branchShortNameEN: {
        
      },
      vatId: {
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      phoneNum: {
        
      },

      addressTH: {
        
      },
      addressEN: {
        
      },
      provinceId: {
        
      },
      amphureId: {
        
      },
      districtId: {
        
      },
      wagePrice: {
        
      },
      vatPer:{

      },
      zoneId:{

      }
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    formDealer() {
      
      this.$v.$touch();
      console.log(this.$v);
    
      if (this.$v.formdealer.$invalid) {
        console.log("false");
        this.submitformDealer = true;
        
      } else {
        // do your submit logic here
        this.postDealerBranch();
      }
    },

    // formDealer() {
    //   this.submitformDealer = true;
    //   this.$v.$touch();
    // },

    postDealerBranch: function() {
      // this.overlayFlag = true;
      const DealerBranchPost = new FormData();
      DealerBranchPost.append("dealerId", this.formdealer.dealerId.dealerId);
      DealerBranchPost.append("brandId", this.formdealer.brandId.brandId);
      DealerBranchPost.append("dealerCode", this.formdealer.dealerBranchId);
      DealerBranchPost.append("nameTh", this.formdealer.branchNameTH);
      DealerBranchPost.append("shortNameTh", this.formdealer.branchShortNameTH);
      DealerBranchPost.append("nameEn", this.formdealer.branchNameEN);
      DealerBranchPost.append("shortNameEn", this.formdealer.branchShortNameEN);
      DealerBranchPost.append("vatId", this.formdealer.vatId);   
      DealerBranchPost.append("hasBp", this.formdealer.isGS != null ? this.formdealer.isGS : "0");
      DealerBranchPost.append("hasGs", this.formdealer.isBP != null ? this.formdealer.isBP : "0");
      DealerBranchPost.append("addressTh", this.formdealer.addressTH);
      DealerBranchPost.append("addressEn", this.formdealer.addressEN);
      DealerBranchPost.append("districtId", this.formdealer.districtId.districtId);
      DealerBranchPost.append("amphurId", this.formdealer.amphureId.amphureId);
      DealerBranchPost.append("provinceId", this.formdealer.provinceId.provinceId);
      DealerBranchPost.append("zoneId", this.formdealer.zoneId.zoneId); //รอเพื่ม
      const fullAddressTh = this.formdealer.addressTH  + " ตำบล" + this.formdealer.districtId.nameTh + " อำเภอ" + this.formdealer.amphureId.nameTh + " จังหวัด" + this.formdealer.provinceId.nameTh
      const fullAddressEn = this.formdealer.addressEN  + " ," + this.formdealer.districtId.nameEn + " ," + this.formdealer.amphureId.nameEn + " ," + this.formdealer.provinceId.nameEn
      // console.log(fullAddressEn);
      DealerBranchPost.append("fullAddressTh", fullAddressTh); 
      DealerBranchPost.append("fullAddressEn", fullAddressEn); 
      DealerBranchPost.append("wagePerHour", this.formdealer.wagePrice); 
      DealerBranchPost.append("vatPer", this.formdealer.vatPer); 

      useNetw
        .post("api/branch/store", DealerBranchPost)
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          this.$router.push({ name: "mainDealerBranch" });
          // console.log(this.cusIdPost);
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.enabled = true;
          // this.Hide = true;
          
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    getDataBrands: function () {
      useNetw
        .get("api/master/vehicle/brands",{
          params: {
            // page: 1,
            // perPage: 10,
            
            
          },
        }) // ?เอามาจากไหน
        .then((response) => {
         
          this.optionsBrands = response.data.data;
          console.log('optionsBrands :' ,this.optionsBrands);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไร
          appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error;
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataDealer: function () {
      useNetw
        .get("api/central/dealers",{
          params: {
            page: 1,
            perPage: 10,
          },
        }) // ?เอามาจากไหน
        .then((response) => {
         
          this.optionsDealer = response.data.data;
          console.log('optionsDealer :' ,this.optionsDealer);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไร
          appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error;
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getProvinces: function() {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.formdealer.provinces = response.data.data;
          console.log('optionsProvinces' , this.formdealer.provinces );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
          
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getAmphures: function(isReset) {
      if (this.formdealer.provinceId != null) {
        //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.formdealer.provinceId.provinceId,
            },
          })
          .then((response) => {
            this.formdealer.amphures = response.data.data;

            if (isReset) {
              this.formdealer.amphureId = null;
              this.formdealer.districtId = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            //skeleton false
          });
      }
    },
    getDistricts: function(isReset) {
      if (this.formdealer.provinceId != null) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.formdealer.amphureId.amphureId,
            },
          })
          .then((response) => {
            this.formdealer.districts = response.data.data;
            console.log('districts :' , this.formdealer.districts);
            if (isReset) {
              this.formdealer.districtId = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <form class="needs-validation" @submit.prevent="formDealer">
              <div class="card-body">
                
                <div class="row">
                <div class="col-md-6 my-auto">
                  <h4 class="card-title">เพิ่มสาขาย่อย</h4>
                </div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'mainDealerBranch' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
            </div>
                <hr />
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="dealerId">ยี่ห้อ (Brands)</label><br />
                      <multiselect
                        v-model="formdealer.brandId"
                        label="nameEn"
                        :options="optionsBrands"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.brandId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitformDealer && $v.formdealer.brandId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.brandId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="dealerId">สาขาหลักที่สร้าง</label><br />
                      <multiselect
                        v-model="formdealer.dealerId"
                        label="nameTh"
                        :options="optionsDealer"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.dealerId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitformDealer && $v.formdealer.dealerId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.dealerId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="dealerBranchId">รหัสสาขา</label>
                      <input
                        v-model="formdealer.dealerBranchId"
                        type="text"
                        class="form-control"
                        placeholder="รหัสสาขา"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.dealerBranchId.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer &&
                          $v.formdealer.dealerBranchId.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.dealerBranchId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="branchNameTH">ชื่อสาขา (ไทย) </label>
                      <input
                        v-model="formdealer.branchNameTH"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อสาขา (ไทย)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.branchNameTH.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.branchNameTH.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.branchNameTH.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameTH"
                        >ชื่อย่อสาขา (ไทย)
                      </label>
                      <input
                        v-model="formdealer.branchShortNameTH"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อย่อสาขา (ไทย)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.branchShortNameTH.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer &&
                          $v.formdealer.branchShortNameTH.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="!$v.formdealer.branchShortNameTH.required"
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchNameEN"
                        >ชื่อสาขา (อังกฤษ)</label
                      >
                      <input
                        v-model="formdealer.branchNameEN"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อสาขา (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.branchNameEN.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.branchNameEN.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.branchNameEN.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ชื่อย่อสาขา (อังกฤษ)</label
                      >
                      <input
                        v-model="formdealer.branchShortNameEN"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อย่อสาขา (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer &&
                            $v.formdealer.branchShortNameEN.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer &&
                          $v.formdealer.branchShortNameEN.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="!$v.formdealer.branchShortNameEN.required"
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >รหัสผู้เสียภาษี</label
                      >
                      <input
                        v-model="formdealer.vatId"
                        type="text"
                        class="form-control"
                        placeholder="รหัสผู้เสียภาษี"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.vatId.$error,
                        }"
                      />
                      <div
                        v-if="submitformDealer && $v.formdealer.vatId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.vatId.minLength && !$v.formdealer.vatId.maxLength">
                          {{ error }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >เบอร์โทร</label
                      >
                      <input
                        v-model="formdealer.phoneNum"
                        type="text"
                        class="form-control"
                        placeholder="เบอร์โทร"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.phoneNum.$error,
                        }"
                      />
                      <div
                        v-if="submitformDealer && $v.formdealer.phoneNum.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.phoneNum.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >แผนก</label
                      >
                      <b-form-checkbox
                        v-model="formdealer.isBP"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ใช้กับศูนย์บริการ (Gs)</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="formdealer.isGS"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ใช้กับศูนย์ซ่อมสีและตัวถัง (Bp)</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <!-- //!End row -->

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ที่อยู่ (ไทย)
                      </label>
                      <input
                        v-model="formdealer.addressTH"
                        type="text"
                        class="form-control"
                        placeholder="ที่อยู่ (ไทย) "
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.addressTH.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.addressTH.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.addressTH.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ที่อยู่ (อังกฤษ)
                      </label>
                      <input
                        v-model="formdealer.addressEN"
                        type="text"
                        class="form-control"
                        placeholder="ที่อยู่ (อังกฤษ)"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.addressEN.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.addressEN.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.addressEN.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //! end row-->

                <div class="row">
                  
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="provinceId"> ภูมิภาค( Zone )</label><br />
                      <multiselect
                        v-model="formdealer.zoneId"
                        label="zoneName"
                        :options="optionsZone"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.zoneId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.zoneId.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.zoneId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="provinceId">จังหวัด</label>
                          <multiselect
                            v-model="formdealer.provinceId"
                            track-by="nameTh"
                            @input="getAmphures"
                            :options="formdealer.provinces"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformDealer &&
                                $v.formdealer.provinceId.$error,
                            }"
                          >
                            <!-- <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>{{ option.nameTh }}</strong></template
                            > -->
                          </multiselect>
                          <div
                            v-if="
                              submitformDealer &&
                                $v.formdealer.provinceId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.provinceId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="amphureId">อำเภอ</label>
                          <multiselect
                            v-model="formdealer.amphureId"
                            track-by="nameTh"
                            :options="formdealer.amphures"
                            @input="getDistricts"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformDealer &&
                                $v.formdealer.amphureId.$error,
                            }"
                          >
                          </multiselect>
                          <div
                            v-if="
                              submitformDealer &&
                                $v.formdealer.amphureId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.formdealer.amphureId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                  <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="districtId">ตำบล</label>
                          <multiselect
                            v-model="formdealer.districtId"
                            track-by="nameTh"
                            :options="formdealer.districts"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformDealer &&
                                $v.formdealer.districtId.$error,
                            }"
                          >
                          </multiselect>
                          <div
                            v-if="
                              submitformDealer &&
                                $v.formdealer.districtId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.formdealer.districtId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                </div>
                <!-- //!end row-->

                <div class="row">

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ค่าแรงต่อชั่วโมง (บาท)
                      </label>
                      <input
                        v-model="formdealer.wagePrice"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.wagePrice.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.wagePrice.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.wagePrice.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ภาษีมูลค่าเพิ่ม (%)
                      </label>
                      <input
                        v-model="formdealer.vatPer"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid':
                            submitformDealer && $v.formdealer.vatPer.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitformDealer && $v.formdealer.vatPer.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formdealer.vatPer.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>


                </div>

                <hr />

                <div class="text-end">
                  <button type="submit" class="btn btn-success " >บันทึก</button>
                </div>
              </div>
            </form>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
